import React from 'react';

const Letter = () => {
    return (
        <div>
            <h1
                className={
                    'text-center font-[Qalisha] text-[#413EBD] text-[3.5rem] py-8 relative bg-white w-full animate__animated animate__slower'
                }
            >
                Thư gửi K39
            </h1>
            <div
                className={
                    'font-[Grotesk] text-sm text-justify px-12 pb-8 text-[#333333] transition-opacity animate__animated animate__delay-2s animate__slower'
                }
            >
                <div>
                    <p className="mt-2">
                        K39 thương mến,
                        <br />
                        <br />
                        Nếu có thể trở về quá khứ để thay đổi một điều, cậu sẽ
                        thay đổi điều gì?
                        <br />
                        <br />
                        Liệu cậu có muốn thay đổi một nguyện vọng trong tờ đơn
                        đăng ký năm ấy, dời lại một chiếc deadline bài tập chưa
                        kịp hoàn thành, sửa chữa một lỗi sai cậu không nên mắc
                        phải…?
                        <br />
                        <br />
                        Hay cậu sẽ thay đổi sự xuất hiện của đại dịch? Bởi trong
                        những ngày thanh xuân rực rỡ nhất, cậu lại chỉ có thể
                        ngắm nhìn thế giới phía sau chiếc màn hình xanh chán
                        ngắt.
                        <br />
                        <br />
                        Hoặc thay đổi những ngày chông chênh của tuổi 22 thì
                        sao? Vì khi đứng trước ngưỡng cửa trưởng thành, phải
                        gồng gánh trên vai bao nỗi lo toan, hẳn cậu đã mệt mỏi
                        nhiều lắm. Giá như thời gian cứ mãi dừng ở những ngày
                        chúng ta 18 thì thật tốt biết bao!
                        <br />
                        <br />
                        Đáng tiếc, cuộc đời chẳng tồn tại hai chữ “giá như”,
                        cũng không có cách nào để chúng ta thay đổi quá khứ. Dù
                        vậy, tớ vẫn tin rằng mọi điều xảy ra đều có lý do của
                        nó. Có lẽ, tuổi trẻ cần một chút gập ghềnh, một chút
                        tiếc nuối mới đủ khiến chúng ta lưu luyến lâu đến vậy.
                        Và nếu những khó khăn đã không thể cản bước cậu thì
                        chúng sẽ tôi luyện cậu trở thành phiên bản mạnh mẽ hơn
                        bao giờ hết.
                        <br />
                        <br />
                        Bốn năm qua đi, chúng ta đã tiến đến trạm dừng cuối cùng
                        của chuyến xe thanh xuân. Trước khi nói lời tạm biệt, tớ
                        muốn tặng cậu một cái ôm thật ấm áp, cậu đã vất vả nhiều
                        rồi và tớ rất tự hào về cậu! Cảm ơn cậu vì vẫn luôn
                        nhiệt huyết, bản lĩnh như vậy, cũng cảm ơn cậu đã khiến
                        cho “vùng đất tương lai” hoang sơ ngày nào giờ đây phủ
                        một màu xanh tươi của niềm hy vọng.
                        <br />
                        <br />
                        Lời cuối, chúc những người bạn K39 của tớ dù ở nơi đâu
                        cũng sẽ thật khoẻ mạnh, hạnh phúc và bình an. Mong rằng
                        những bài học và kỷ niệm tươi đẹp khi là sinh viên
                        trường Báo sẽ giúp cậu vững bước trên chặng đường sắp
                        tới. Còn tớ vẫn luôn ở đây dõi theo và ủng hộ cậu. Chờ
                        một ngày nắng khác, chúng ta sẽ gặp lại nhau nhé!
                    </p>
                    <p className="text-right my-6">
                        Bạn của cậu,
                        <br />
                        Phút Cuối 2023 - Nơi ta dừng chân.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Letter;
