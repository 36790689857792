import React, { useState } from 'react';
import { RouletteSpinnerOverlay } from 'react-spinner-overlay';

const Modal = ({ isShowPopup }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleLoading = () => {
        setIsLoading(true);
    };

    return (
        <div
            id="pop-up"
            className={
                (isShowPopup ? 'opacity-100' : 'opacity-0') +
                ' fixed bg-gray-600 bg-opacity-25 top-0 bottom-0 left-0 right-0 px-6 w-screen transition-opacity duration-1000	'
            }
        >
            <RouletteSpinnerOverlay
                color="#5e79d8"
                size={48}
                loading={isLoading && isShowPopup}
                overlayColor="rgb(200,200,200,0.5)"
            />
            <div
                style={{
                    backgroundImage:
                        'url(' + process.env.PUBLIC_URL + '/assets/popup.png)',
                }}
                alt="pop-up-background"
                className={
                    'animate__animated mt-[36vh] bg-no-repeat w-full bg-cover h-52 text-center font-[Grotesk] text-2xl pt-16 pb-4 text-[#333] shadow-2xl max-w-xl mx-auto' +
                    (!isShowPopup
                        ? ' animate__fadeOutDown'
                        : ' animate__fadeInUp animate_delay-2s animate__slow')
                }
            >
                <h2>Bạn đã sẵn sàng</h2>
                <h2>LOGGING OUT khỏi AJC chưa?</h2>
                <div className="flex flex-row w-full justify-center">
                    <img
                        alt="button"
                        src={process.env.PUBLIC_URL + '/assets/yes.png'}
                        className="w-24 m-4 shadow-lg animate-pulse"
                        onClick={handleLoading}
                    />
                    <img
                        alt="button"
                        src={process.env.PUBLIC_URL + '/assets/cos.png'}
                        className="w-24 m-4 shadow-lg animate-pulse"
                        onClick={handleLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default Modal;
