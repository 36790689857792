import React, { useEffect, useRef, useState } from 'react';
import { onValue, ref } from 'firebase/database';
import Letter from '../components/Letter';
import Modal from '../components/Modal';

import 'animate.css';
import { database } from '../config';

const MainScreen = () => {
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [isEnd, setIsEnd] = useState(false);
    const openedNewTabRef = useRef(false);

    const stateRef = ref(database, '/');
    onValue(stateRef, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        if (data.isShowPopUp !== isShowPopup) setIsShowPopup(data.isShowPopUp);
    });

    useEffect(() => {
        setTimeout(() => {
            if (!openedNewTabRef?.current) {
                window.open('https://trananhpremium.com/', '_blank')?.focus();
                openedNewTabRef.current = true;
            }
        }, 90000);
    }, []);

    return (
        <div className="min-h-screen">
            <img
                src={process.env.PUBLIC_URL + '/assets/head.webp'}
                alt="header"
                className="w-full animate__animated animate__fadeIn animate__slow"
            />
            <Letter isEnd={isEnd} />
            <img
                src={process.env.PUBLIC_URL + '/assets/foot.webp'}
                alt="footer"
                className="w-full mt-auto"
            />
            <Modal
                isShowPopup={isShowPopup}
                setIsShowPopup={setIsShowPopup}
                isEnd={isEnd}
                setIsEnd={setIsEnd}
            />
        </div>
    );
};

export default MainScreen;
